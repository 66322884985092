import { HOST, WS_HOST } from 'constants/hosts';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Game, { GameParams } from 'types/game';

const useWebsockets = (
    enabled: boolean,
    gameType: number | undefined,
    gameParams: GameParams | undefined,
    onMove: (color: number) => void,
    onDisconnect: () => void,
    setPlayerID: (id: number) => void,
    updateGame: (game: Game) => void,
    validateGame: (hash: string) => boolean,
    backHome: () => void,
    isSpectator: boolean
) => {
    const { search } = useLocation();
    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    const [isLobbyReady, setIsLobbyReady] = useState(false);
    const [isWebSocketsOpened, setIsWebsocketsOpened] = useState(false);
    const [socket, setSocket] = useState<WebSocket>();
    const [lobby, setLobby] = useState<string | null>();
    const [lobbyJoin, setLobbyJoin] = useState<string | null>(null);
    const [error, setError] = useState<{
        title: string;
        description: string;
    } | null>();
    const [isDisconnected, setIsDisconnected] = useState<boolean>(false);
    const [lobbyLink, setLobbyLink] = useState<string | null>(null);
    const [isPrivateLobby, setIsPrivateLobby] = useState<boolean>(false);
    const [isPlayer, setisPlayer] = useState<boolean>(!isSpectator);

    useEffect(() => {
        if (enabled !== isEnabled) setIsEnabled(enabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    useEffect(() => {
        if (isPlayer === isSpectator) setisPlayer(!isSpectator)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSpectator])

    useEffect(() => {
        if (!isEnabled) return;

        let searchParams = new URLSearchParams(search);

        let newSocket = new WebSocket(WS_HOST);
        setIsLobbyReady(false);
        setIsWebsocketsOpened(false);
        setLobby(null);
        setSocket(newSocket);
        setError(null);
        setIsDisconnected(false);

        let lobby_from_search = searchParams?.get('lobby');
        if (!!lobby_from_search) {
            setIsPrivateLobby(true);
            setLobbyJoin(lobby_from_search);
        } else {
            setIsPrivateLobby(false);
            setLobbyJoin(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled]);

    useEffect(() => {
        return () => {
            // console.log('cloes')
            socket?.close();
        };
    }, [socket]);

    useEffect(() => {
        if (lobby && gameParams) setLobbyLink(`${HOST}/game?lobby=${lobby}`);
        else setLobbyLink(null);
    }, [lobby, gameParams]);

    useEffect(() => {
        if (!isWebSocketsOpened || !socket || isLobbyReady) return;
        if (!isPrivateLobby) {
            if (gameType !== undefined) {
                socket.send(`/find ${gameType}`);
                setisPlayer(true);
            }
            if (gameParams !== undefined)
                if (isSpectator) {
                    socket.send(`/create_lobby ${JSON.stringify(gameParams)}`);
                    setisPlayer(false);
                } else {
                    socket.send(`/private ${JSON.stringify(gameParams)}`);
                    setisPlayer(true);
                }
        } else {
            if (!!lobbyJoin) socket.send(`/join ${lobbyJoin}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWebSocketsOpened]);

    useEffect(() => {
        if (!socket) return;

        const lobbyRegex = /^\/lobby \S+$/;
        const connectPlayerRegex = /^\/connect$/;
        const moveRegex = /^\/move \d+/;
        const stateRegex = /^\/state /;
        const disconnectRegex = /^\/disconnect$/;
        const playerIDRegex = /^\/playerID \d+$/;
        const errorRegex = /^!!! /;
        const gameRegex = /^\/start \S+/;
        const currentFieldRegex = /^\/current_field \S+/;

        socket.onopen = (e) => setIsWebsocketsOpened(true);
        socket.onmessage = (e) => {
            let data: string = e.data;
            if (data.match(lobbyRegex)) setLobby(data.split(' ')[1]);
            if (data.match(connectPlayerRegex)) console.log('PLAYER CONNECTED');
            if (data.match(disconnectRegex)) {
                setIsDisconnected(true);
                setIsLobbyReady(false);
                onDisconnect();
            }
            if (data.match(moveRegex)) onMove(parseInt(data.split(' ')[1]));
            if (data.match(playerIDRegex))
                setPlayerID(parseInt(data.split(' ')[1]));
            if (data.match(stateRegex)) {
                let json_data = data.replace('/state ', '');
                let game: Game = JSON.parse(json_data);
                updateGame(game);
            }
            if (data.match(errorRegex)) {
                console.error(data.replace('!!! ', ''));
                setError({
                    title: 'error request',
                    description: data.replace('!!! ', ''),
                });
            }
            if (data.match(gameRegex)) {
                let json_data = data.replace('/start ', '');
                let game: Game = JSON.parse(json_data);
                setIsLobbyReady(true);
                updateGame(game);
            }
            if (data.match(currentFieldRegex)) {
                let valid = validateGame(data.replace('/current_field ', ''));
                if (!valid) socket.send('/state');
            }
        };
        socket.onclose = () => backHome();
        socket.onclose = () => console.warn('close');
        socket.onerror = (e) => console.error(e);
    }, [
        backHome,
        onDisconnect,
        onMove,
        setPlayerID,
        socket,
        updateGame,
        validateGame,
    ]);

    const move = (color: number) => {
        if (!socket || !isWebSocketsOpened) return;
        socket?.send(`/move ${color}`);
    };

    return {
        isLobbyReady,
        lobby,
        error,
        isDisconnected,
        move,
        lobbyLink,
        isPlayer,
    };
};

export default useWebsockets;
