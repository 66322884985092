import { useEffect, useState } from 'react';
import { createContainer } from 'react-gstore';
import { GameProps, PlayerRole } from 'types/game';

const defaultState: GameProps = {
    game_type: 0,
    bot_level: 0,
    count_colors: 5,
    field_type: 0,
    start_cells_type: 0,
    field_size: 0,
    multiplayerGameType: 0,
    multiplayerType: 9,
    playerRole: 'PLAYER'
};

const useNewGame = (initialState?: GameProps) => {
    let [game_type, setGameType] = useState(
        initialState?.game_type ?? defaultState.game_type
    );
    let [field_type, setFieldType] = useState(
        initialState?.field_type ?? defaultState.field_type
    );
    let [count_colors, setCountColors] = useState(
        initialState?.count_colors ?? defaultState.count_colors
    );
    let [start_cells_type, setStartCellsType] = useState(
        initialState?.start_cells_type ?? defaultState.start_cells_type
    );
    let [field_size, setFieldSize] = useState(
        initialState?.field_size ?? defaultState.field_size
    );
    let [bot_level, setBotLevel] = useState(
        initialState?.bot_level ?? defaultState.bot_level
    );
    let [seed, setSeed] = useState(initialState?.seed ?? defaultState.seed);
    let [multiplayerType, setMultiplayerType] = useState(
        initialState?.multiplayerType ?? defaultState.multiplayerType
    );
    let [multiplayerGameType, setMultiplayerGameType] = useState(
        initialState?.multiplayerType ?? defaultState.multiplayerGameType
    );
    let [playerRole, setPlayerRole] = useState<PlayerRole>(
        initialState?.playerRole ?? defaultState.playerRole
    )

    useEffect(() => {
        setStartCellsType(defaultState.start_cells_type);
        setFieldSize(defaultState.field_size);
    }, [field_type]);

    return {
        game_type,
        setGameType,
        field_type,
        setFieldType,
        count_colors,
        setCountColors,
        start_cells_type,
        setStartCellsType,
        field_size,
        setFieldSize,
        bot_level,
        setBotLevel,
        seed,
        setSeed,
        multiplayerType,
        setMultiplayerType,
        multiplayerGameType,
        setMultiplayerGameType,
        playerRole,
        setPlayerRole,
    };
};

export default createContainer(useNewGame, true, {
    name: 'NEW_GAME_STORE',
    prefix: 'TILER_',
});
