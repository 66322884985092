import Locale from 'types/locale';

type ModalData = {
    header: Locale;
    body: Locale;
};

const modalWin: ModalData = {
    header: {
        en: 'Game over',
        ru: 'Игра окончена',
    },
    body: {
        en: 'You won',
        ru: 'Вы выиграли',
    },
};

const modalLost: ModalData = {
    header: {
        en: 'Game over',
        ru: 'Игра окончена',
    },
    body: {
        en: 'You lost',
        ru: 'Вы проиграли',
    },
};

const modals = {
    modalWin,
    modalLost,
};

export default modals;
