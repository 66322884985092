import about from 'constants/about';
import { useNavigate } from 'react-router-dom';
import settingsStore from 'store/settingsStore';

const About = () => {
    const navigate = useNavigate();
    const lang = settingsStore.useContainer().lang;
    return (
        <div className="about-main">
            <header>
                {about.title[lang]}
                <div onClick={() => navigate(-1)} className="close-icon" />
            </header>
            <p>{about.data[lang]}</p>
        </div>
    );
};

export default About;
