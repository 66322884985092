type LinkCopyParams = {
    link: string;
    onCopy?: () => void;
};

const LinkCopy = (params: LinkCopyParams) => {
    const onCopy = async (text: string) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text && text);
        }
    };

    return (
        <div className="link-copy-block" onClick={() => onCopy(params.link)}>
            <span>{params.link}</span>
            <div className="copy-icon" />
        </div>
    );
};

export default LinkCopy;
