import ReactDOM from 'react-dom';

type ModalProps = {
    body: React.ReactNode;
    header?: React.ReactNode;
    onClose?: () => void;
    okButton?: React.ReactNode;
    cancleButton?: React.ReactNode;
    isOpen?: boolean;
    onOk?: () => void;
    onCancle?: () => void;
};

const Modal = (props: ModalProps) => {
    if (!props.isOpen) return <></>;

    const handleOk = () => {
        if (props.onOk) props.onOk();
        if (props.onClose) props.onClose();
    };

    const handleCancle = () => {
        if (props.onCancle) props.onCancle();
        if (props.onClose) props.onClose();
    };

    return ReactDOM.createPortal(
        <div className="modal--background" onClick={props.onClose}>
            <div className="modal--main" onClick={(e) => e.stopPropagation()}>
                {props.header && (
                    <div className="modal--header">{props.header}</div>
                )}
                <div className="modal--body">{props.body}</div>
                {(props.okButton || props.cancleButton) && (
                    <div className="modal--footer">
                        {props.cancleButton && (
                            <button onClick={handleCancle}>
                                {props.cancleButton}
                            </button>
                        )}
                        {props.okButton && (
                            <button onClick={handleOk}>{props.okButton}</button>
                        )}
                    </div>
                )}
            </div>
        </div>,
        document.body
    );
};

export default Modal;
