import { useEffect, useState } from 'react';
import { createContainer } from 'react-gstore';
import Game, { CurrentOfflineGame } from 'types/game';

const useCurrentOfflineGame = (initialState?: CurrentOfflineGame) => {
    const [gameType, setGameType] = useState<number | undefined>(
        initialState?.gameType
    );
    const [botLevel, setBotLevel] = useState<number | undefined>(
        initialState?.botLevel
    );
    const [game, setGame] = useState<Game | undefined>(initialState?.game);
    const [isExists, setIsExsits] = useState<boolean>(!!initialState?.game);

    useEffect(() => {
        setIsExsits(
            game !== undefined &&
                botLevel !== undefined &&
                gameType !== undefined
        );
    }, [game, botLevel, gameType]);

    const init = (gameType: number, botLevel: number, game: Game) => {
        setBotLevel(botLevel);
        setGame(game);
        setGameType(gameType);
    };

    const reset = () => {
        setBotLevel(undefined);
        setGame(undefined);
        setGameType(undefined);
    };

    return {
        gameType,
        setGameType,
        botLevel,
        setBotLevel,
        game,
        setGame,
        init,
        reset,
        isExists,
    };
};

export default createContainer(useCurrentOfflineGame, true, {
    name: 'CURRENT_OFFILNE_GAME_STORE',
    prefix: 'TILER_',
});
