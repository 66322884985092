import ToggleButton from 'components/ToggleButton';
import settingsStore from 'store/settingsStore';
import settings from 'constants/settings';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const navigate = useNavigate();
    const store = settingsStore.useContainer();

    return (
        <div className="settings-main">
            <header>
                <h1>Settings</h1>
                <div
                    className="close-icon"
                    onClick={() => navigate(-1)}
                    style={{ cursor: 'pointer' }}
                />
            </header>
            <input
                placeholder={settings['name'].name[store.lang]}
                value={store.name}
                type="text"
                onChange={(e) => store.setName(e.target.value)}
            ></input>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h2>{settings['theme'].name[store.lang]}</h2>
                <ToggleButton
                    onChange={store.anotherTheme}
                    isOn={store.theme === 0}
                    width={'13%'}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h2>{settings['devmode'].name[store.lang]}</h2>
                <ToggleButton
                    onChange={(isOn) => store.setDevMode(isOn)}
                    isOn={store.devMode}
                    width={'13%'}
                />
            </div>
            <div>
                <h2>{settings['lang'].name[store.lang]}</h2>
                <select
                    value={store.lang}
                    onChange={(e) => {
                        //@ts-ignore
                        store.setLang(e.target.value);
                    }}
                >
                    <option value="ru">Русский</option>
                    <option value="en">English</option>
                </select>
            </div>
        </div>
    );
};

export default Settings;
