import { useState } from 'react';
import { createContainer } from 'react-gstore';
import { AcceptLangs } from 'types/locale';

type UserSettingsProps = {
    theme: number;
    name: string;
    lang: AcceptLangs;
    devMode: boolean;
};

const useSettings = (
    initialState: UserSettingsProps = {
        theme: 0,
        name: '',
        lang: 'en',
        devMode: false,
    }
) => {
    let [theme, setTheme] = useState(initialState.theme);
    let [name, setName] = useState(initialState.name);
    let [devMode, setDevMode] = useState(initialState.devMode);
    let [lang, setLang] = useState<AcceptLangs>(initialState.lang);
    let dark = () => setTheme(0);
    let light = () => setTheme(1);
    let anotherTheme = () => setTheme((theme + 1) % 2);

    return {
        theme,
        dark,
        light,
        anotherTheme,
        name,
        setName,
        lang,
        setLang,
        devMode,
        setDevMode,
    };
};

export default createContainer(useSettings, true, {
    name: 'SETTINGS_STORE',
    prefix: 'TILER_',
});
