import { ReactNode } from 'react';

const Loading = (props: { title?: ReactNode }) => {
    return (
        <div className="tiler-loader-main">
            <div className="tiler-loader">
                <div className="cell d-0"></div>
                <div className="cell d-1"></div>
                <div className="cell d-2"></div>
                <div className="cell d-3"></div>
                <div className="cell d-1"></div>
                <div className="cell d-2"></div>
                <div className="cell d-3"></div>
                <div className="cell d-4"></div>
                <div className="cell d-2"></div>
                <div className="cell d-3"></div>
                <div className="cell d-4"></div>
                <div className="cell d-5"></div>
                <div className="cell d-3"></div>
                <div className="cell d-4"></div>
                <div className="cell d-5"></div>
                <div className="cell d-6"></div>
            </div>
            {props.title && (
                <div className="tiler-loader--title">{props.title}</div>
            )}
        </div>
    );
};

export default Loading;
