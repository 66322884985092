import Modal from 'components/Modal';
import modals from 'constants/modals';
import settingsStore from 'store/settingsStore';

type ModalLostProps = {
    isOpen?: boolean;
    onClose?: () => void;
};

const ModalLost = (props: ModalLostProps) => {
    const lang = settingsStore.useContainer().lang;

    return (
        <Modal
            {...props}
            header={modals.modalLost.header[lang]}
            body={modals.modalLost.body[lang]}
            okButton="Ok"
        />
    );
};

export default ModalLost;
