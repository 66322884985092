import IHash from 'types/iHash';

const settings: IHash<{ name: IHash<string> }> = {
    name: {
        name: { ru: 'Имя пользователя', en: 'your nickname' },
    },
    theme: {
        name: { ru: 'Темная тема', en: 'Dark theme' },
    },
    devmode: {
        name: { ru: 'Режим разработчика', en: 'Developer mode' },
    },
    lang: {
        name: { ru: 'Язык', en: 'Language' },
    },
};

export default settings;
