import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.css';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import settingsStore from 'store/settingsStore';
import { Provider } from 'react-gstore';
import gameStore from 'store/newGameStore';
import Home from 'pages/Home';
import Game from 'pages/Game';
import Settings from 'pages/Settings';
import About from 'pages/About';
import currentOfflineGameStore from 'store/currentOfflineGameStore';

if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
}

interface RootComponentProps {
    children?: React.ReactNode;
}

const RootComponent = (props: RootComponentProps) => {
    let settings = settingsStore.useContainer();
    if (settings.theme === 0) {
        document.body.style.color = 'white';
        document.body.style.backgroundColor = '#333333';
        document.body.style.setProperty('--bg', '#333333');
        document.body.style.setProperty('--tc', 'white');
    } else {
        document.body.style.color = '#000000';
        document.body.style.backgroundColor = '#ffffff';
        document.body.style.setProperty('--bg', '#ffffff');
        document.body.style.setProperty('--tc', '#000000');
    }
    return <>{props.children}</>;
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider
            containers={[
                { container: gameStore },
                { container: settingsStore },
                { container: currentOfflineGameStore },
            ]}
        >
            <RootComponent>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/game" element={<Game />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/about" element={<About />} />
                    </Routes>
                </Router>
            </RootComponent>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
