import { useEffect } from 'react';
import { useState } from 'react';
import gameStore from 'store/newGameStore';
import settingsStore from 'store/settingsStore';
import { FieldType } from 'types/field';
import * as tilerBackend from 'tiler_wasm';
import Locale from 'types/locale';
import Game, { roles } from 'types/game';
import Loading from 'components/Loading';
import { useNavigate } from 'react-router-dom';
import currentOfflineGameStore from 'store/currentOfflineGameStore';

type ParamItem = {
    name: Locale;
    values: number[];
    names?: Locale[];
    value: number;
    setValue: (value: number) => any;
    id: string;
    isShow: boolean;
};

const Home = () => {
    const navigate = useNavigate();
    const { lang } = settingsStore.useContainer();
    const store = gameStore.useContainer();
    const currentGameStore = currentOfflineGameStore.useContainer();

    const [fields, setFields] = useState<FieldType[]>([]);
    const [bots, setBots] = useState<Locale[]>([]);
    const [isWasmLoaded, setIsWasmLoaded] = useState<boolean>(false);
    // const [gameParams, setGameParams] = useState<ParamItem[]>([])

    useEffect(() => {
        tilerBackend
            .default()
            .then(() => setIsWasmLoaded(true))
            .catch(() => console.error('ERROR WASM'));
    }, []);

    useEffect(() => {
        if (isWasmLoaded && tilerBackend) {
            let fields = tilerBackend.get_fields();
            let bots = tilerBackend.get_bots();
            setFields(fields);
            setBots(bots);
        }
    }, [isWasmLoaded]);

    if (!isWasmLoaded || fields.length === 0 || bots.length === 0) {
        return <Loading />;
    }

    let buttonNewGameName: Locale = { en: 'New game', ru: 'Новая игра' };
    let buttonContinueGame: Locale = {
        en: 'Continue game',
        ru: 'Продолжить игру',
    };

    const handleNewGame = () => {
        if (isWasmLoaded && store.game_type !== 2) {
            const game: Game = tilerBackend.generate_game(
                store.field_type,
                store.field_size,
                store.count_colors,
                store.start_cells_type
            );
            
            console.log(game);

            currentGameStore.init(store.game_type, store.bot_level, game);
        }

        navigate('game');
    };

    const gameParams: Array<ParamItem> = [
        {
            // gameType
            name: { en: 'Game type', ru: 'Тип игры' },
            values: [0, 1, 2],
            names: [
                { en: 'Solo', ru: 'Одиночная игра' },
                { en: 'Multiplayer offline', ru: 'Мульплеер оффлайн' },
                { en: 'Multiplayer online', ru: 'Мульплеер онлайн' },
            ],
            value: store.game_type,
            setValue: store.setGameType,
            id: '897ac853-e641-49cb-8f77-ebb3faf768f5',
            isShow: true,
        },
        {
            // botsTypes
            name: { en: 'Bot type', ru: 'Тип бота' },
            values: Array.from(Array(bots.length).keys()),
            names: bots,
            value: store.bot_level,
            setValue: store.setBotLevel,
            id: 'ecfb6dde-fc45-4268-abcf-b6bff3bf3a87',
            isShow: store.game_type === 0,
        },
        {
            // multiplayerType
            name: { en: 'Online game type', ru: 'Тип онлайн игры' },
            values: [0, 1],
            names: [
                { ru: 'Публичная игра', en: 'Public game' },
                { ru: 'Приватная игра', en: 'Private game' },
            ],
            value: store.multiplayerType,
            setValue: store.setMultiplayerType,
            id: '4d4611bc-dc6b-4726-a875-b32340b184b1',
            isShow: store.game_type === 2,
        },
        {
            // online game user role
            name: { en: 'Your role', ru: 'Ваша роль' },
            values: Array.from(Array(roles.length).keys()),
            names: [
                { ru: 'Игрок', en: 'Player' },
                { ru: 'Наблюдатель', en: 'Spectator' },
            ],
            value: roles.findIndex((el) => el === store.playerRole),
            setValue: (index) => store.setPlayerRole(roles[index]),
            id: '4d4611bc-dc6b-4723-a875-b32340b184b2',
            isShow: store.game_type === 2 && store.multiplayerType === 1,
        },
        {
            // multiplayerGameType
            name: { en: 'Field type', ru: 'Тип поля' },
            values: [0, 1, 2, 3],
            names: [
                { en: 'Small', ru: 'Небольшое поле' },
                { en: 'Medium', ru: 'Среднее поле' },
                { en: 'Small rooms', ru: 'Небольшие комнаты' },
                { en: 'Medium rooms', ru: 'Средние комнаты' },
            ],
            value: store.multiplayerGameType,
            setValue: store.setMultiplayerGameType,
            id: 'e2816d12-e0ea-4cc1-9193-5bce95f07fd5',
            isShow: store.game_type === 2 && store.multiplayerType === 0,
        },
        {
            // countColors
            name: { en: 'Count colors', ru: 'Колличество цветов' },
            values: [5, 6, 7, 8, 9, 10, 11],
            value: store.count_colors,
            setValue: store.setCountColors,
            id: '45cabdd9-fbf0-42a2-9547-a91163790c4a',
            isShow: !(store.game_type === 2 && store.multiplayerType === 0),
        },
        {
            // fieldType
            name: { en: 'Field type', ru: 'Тип игрового поля' },
            values: fields.map((_, idx) => idx),
            names: fields.map((item) => item.title),
            value: store.field_type,
            setValue: store.setFieldType,
            id: 'ba6f8110-4d02-4236-b57a-f118f54cb683',
            isShow: !(store.game_type === 2 && store.multiplayerType === 0),
        },
        {
            // fieldSize
            name: { en: 'Field size', ru: 'Размер игрового поля' },
            values: fields[store.field_type].sizes.map((item, idx) => idx),
            names: fields[store.field_type].sizes.map((item) => {
                return { ru: `${item.x}x${item.y}`, en: `${item.x}x${item.y}` };
            }),
            value: store.field_size,
            setValue: store.setFieldSize,
            id: '75493e66-a458-4440-8fce-fba5db6e6eae',
            isShow: !(store.game_type === 2 && store.multiplayerType === 0),
        },
        {
            // startCells
            name: { en: 'Start cells stype', ru: 'Тип начальных точек' },
            values: fields[store.field_type].start_cells.map(
                (item, idx) => idx
            ),
            names: fields[store.field_type].start_cells,
            value: store.start_cells_type,
            setValue: store.setStartCellsType,
            id: 'e64a73b6-1b01-49e0-ae23-6cdd469c8e2a',
            isShow: !(store.game_type === 2 && store.multiplayerType === 0),
        },
    ];

    return (
        <div className="home-main">
            <header>
                <h1>Tiler 4.0</h1>
                <div style={{ display: 'flex', gap: 15 }}>
                    <a href="/about">
                        <div className="about-icon" />
                    </a>
                    <a href="/settings">
                        <div className="settings-icon" />
                    </a>
                </div>
            </header>
            {gameParams
                .map((param) => {
                    return (
                        <div key={param.id} style={!param.isShow ? {display: 'none'} : {}}>
                            <h2>{param.name[lang]}</h2>
                            <select
                                value={param.value}
                                onChange={(e) =>
                                    param.setValue(parseInt(e.target.value))
                                }
                            >
                                {param.names
                                    ? param.names.map((name, idx) => (
                                          <option
                                              key={`${param.id}-${name.en}`}
                                              value={param.values[idx]}
                                          >
                                              {name[lang]}
                                          </option>
                                      ))
                                    : param.values.map((name) => (
                                          <option
                                              key={`${param.id}-${name}`}
                                              value={name}
                                          >
                                              {name}
                                          </option>
                                      ))}
                            </select>
                        </div>
                    );
                })}
            <div className="home-page-buttons">
                <button className="my-button" onClick={handleNewGame}>
                    {buttonNewGameName[lang]}
                </button>
                {currentGameStore.isExists ? (
                    <a href="/game?continue">
                        <div className="my-button">
                            {buttonContinueGame[lang]}
                        </div>
                    </a>
                ) : null}
            </div>
        </div>
    );
};

export default Home;
