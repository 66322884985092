import Modal from 'components/Modal';
import modals from 'constants/modals';
import settingsStore from 'store/settingsStore';

type ModalWinProps = {
    isOpen?: boolean;
    onClose?: () => void;
};

const ModalWin = (props: ModalWinProps) => {
    const lang = settingsStore.useContainer().lang;

    return (
        <Modal
            {...props}
            header={modals.modalWin.header[lang]}
            body={modals.modalWin.body[lang]}
            okButton="Ok"
        />
    );
};

export default ModalWin;
