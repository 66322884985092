const colors = [
    '#026d35',
    '#5b23a4',
    '#05f0e4',
    '#e30000',
    '#ff3ac2',
    '#fd791a',
    '#1c9bfd',
    '#dde026',
    '#08b35c',
    '#b8b8b8',
    '#bb92fc',
];

export default colors;
