import Loading from 'components/Loading';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Game, { GameState } from 'types/game';
import * as tilerBackend from 'tiler_wasm';
import colors from 'constants/colors';
import ModalWin from 'modals/ModalWin';
import ModalLost from 'modals/ModalLost';
import newGameStore from 'store/newGameStore';
import React from 'react';
import currentOfflineGameStore from 'store/currentOfflineGameStore';
import useWebsockets from 'hooks/useWebsockets';
import LinkCopy from 'components/LinkCopy';
import Locale from 'types/locale';
import settingsStore from 'store/settingsStore';

const GamePage = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const store = newGameStore.useContainer();
    const lang = settingsStore.useContainer().lang;
    const currentGame = currentOfflineGameStore.useContainer();

    const [yourIDX, setYourIds] = useState(0);

    const [isBackendLoaded, setIsBackendLoaded] = useState(false);

    const [gameState, setGameState] = useState<GameState>();

    let [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    let svgRef = useRef(null);
    let [svgList, setSvgList] = useState<JSX.Element[]>([]);

    const [fieldHeight, setFieldHeight] = useState<number>();
    const [fieldWidth, setFieldWidth] = useState<number>();

    const [loadingDescription, setLoadingDescription] = useState<
        Locale | undefined
    >(undefined);

    const onMove = (color: number) => {
        if (isBackendLoaded && gameState?.game) {
            let new_game: Game = tilerBackend.step(
                {
                    field: gameState.game.field,
                    players: gameState.game.players,
                    current_player: gameState.game.current_player,
                    count_colors: gameState.game.count_colors,
                    seed: gameState.game.seed,
                    steps: gameState.game.steps,
                },
                color
            );
            setGameState({ ...gameState, game: new_game });
        }
    };

    const validate = (hash: string) => {
        if (!gameState?.game) return true;
        let valid: boolean = tilerBackend.validate(
            {
                field: gameState.game.field,
                players: gameState.game.players,
                current_player: gameState.game.current_player,
                count_colors: gameState.game.count_colors,
                seed: gameState.game.seed,
                steps: gameState.game.steps,
            },
            hash
        );

        return valid;
    };

    const onDisconnect = () => {
        setLoadingDescription({
            ru: 'Противник покинул игру',
            en: 'The opponent has left the game',
        });
        setTimeout(() => navigate('/'), 2000);
    };

    const onback = (message?: Locale) => {
        setLoadingDescription({
            ru: 'Отключение от лобби. ' + message?.ru,
            en: 'Disconnecting from the lobby. ' + message?.en,
        });
        setTimeout(() => navigate('/'), 2000);
    };

    const { isLobbyReady, lobbyLink, move, isPlayer } = useWebsockets(
        gameState?.type === 'online',
        gameState?.type === 'online' && store.multiplayerType === 0
            ? store.multiplayerGameType
            : undefined,
        gameState?.type === 'online' && store.multiplayerType === 1
            ? {
                  field_type: store.field_type,
                  field_size: store.field_size,
                  count_colors: store.count_colors,
                  start_cells_type: store.start_cells_type,
              }
            : undefined,
        onMove,
        onDisconnect,
        (id: number) => setYourIds(id),
        (game: Game) => {
            if (gameState) setGameState({ ...gameState, game });
        },
        validate,
        onback,
        store.playerRole !== 'PLAYER' || store.multiplayerGameType !== 1
    );

    useEffect(() => {
        let params = new URLSearchParams(search);
        if (
            (store.game_type === 2 || params.get('lobby') !== null) &&
            params.get('continue') === null
        ) {
            setGameState({
                type: 'online',
                game: undefined,
                isReady: false,
            });
        } else {
            setGameState({
                type: 'offline',
                game: currentGame.game,
                isReady: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.game_type]);

    useEffect(() => {
        tilerBackend
            .default()
            .then(() => setIsBackendLoaded(true))
            .catch(() => console.error('ERROR WASM'));
    }, []);

    useEffect(() => {
        if (gameState?.type === 'offline' && gameState.game) {
            currentGame.setGame(gameState.game);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState]);

    useEffect(() => {
        const updateSize = () => {
            setSize([document.body.clientWidth, document.body.clientHeight]);
        };

        if (gameState?.type === 'offline') {
            if (!currentGame.isExists) {
                navigate('/');
            }
        }

        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, gameState]);

    useEffect(() => {
        if (!gameState?.game) return;
        let game = gameState.game;
        let hw = game ? game.field.field_size.x / game.field.field_size.y : 1;
        let fieldWidth = size[1];
        let fieldHeight = fieldWidth * hw;

        if (size[1] < size[0]) {
            fieldHeight = size[1] * 5;
            fieldWidth = fieldHeight * hw;
        }
        setFieldHeight(fieldHeight);
        setFieldWidth(fieldWidth);
    }, [gameState?.game, size]);

    useEffect(() => {
        const updateField = () => {
            if (!fieldHeight || !fieldWidth) return;
            let w = fieldWidth;
            let h = fieldHeight;

            let svgListCopy: JSX.Element[] = [];

            let invert = {
                x: false,
                y: false,
            };

            let game = gameState?.game;

            if (game) {
                if (w < h) {
                    for (let cell of game.field.data) {
                        let x = cell.coord.y * w + cell.size.y * w * 0.01;
                        let y = cell.coord.x * h + cell.size.x * h * 0.01;
                        let width = cell.size.y * w * 0.98;
                        let height = cell.size.x * h * 0.98;
                        if (invert.x) x = w - x - width;
                        if (invert.y) y = h - y - height;

                        let radius = Math.min(cell.size.x * h * 0.15, cell.size.y * w * 0.15)

                        svgListCopy.push(
                            <rect
                                x={x}
                                y={y}
                                width={width}
                                height={height}
                                fill={
                                    cell.owner === undefined ||
                                    cell.owner === null
                                        ? colors[cell.color]
                                        : colors[
                                              game
                                                  ? game.players[cell.owner]
                                                        .color
                                                  : 0
                                          ]
                                }
                                rx={radius}
                                ry={radius}
                                stroke="black"
                                strokeWidth={
                                    (cell.owner === undefined ||
                                    cell.owner === null)
                                        ? 0
                                        : Math.min(cell.size.x * h * 0.1, cell.size.y * w * 0.1, 0)
                                }
                                key={`${x}-${y}-${cell.color}`}
                            />
                        );
                    }
                } else {
                    for (let cell of game.field.data) {
                        let x = cell.coord.x * w + cell.size.x * w * 0.01;
                        let y = cell.coord.y * h + cell.size.y * h * 0.01;
                        let width = cell.size.x * w * 0.98;
                        let height = cell.size.y * h * 0.98;
                        if (invert.x) x = w - x - width;
                        if (invert.y) y = h - y - height;

                        let radius = Math.min(cell.size.x * h * 0.15, cell.size.y * w * 0.15)

                        svgListCopy.push(
                            <rect
                                x={x}
                                y={y}
                                width={width}
                                height={height}
                                fill={
                                    cell.owner === undefined ||
                                    cell.owner === null
                                        ? colors[cell.color]
                                        : colors[
                                              game
                                                  ? game.players[cell.owner]
                                                        .color
                                                  : 0
                                          ]
                                }
                                rx={radius}
                                ry={radius}
                                stroke="black"
                                strokeWidth={
                                    (cell.owner === undefined ||
                                    cell.owner === null)
                                        ? 0
                                        : Math.min(cell.size.x * h * 0.1, cell.size.y * w * 0.1, 0)
                                }
                                key={`${x}-${y}-${cell.color}`}
                            />
                        );
                    }
                }
            }

            setSvgList(svgListCopy);
        };

        updateField();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size, gameState?.game, fieldWidth, fieldHeight]);

    const handleMove = (color: number) => {
        if (
            gameState?.type === 'online' &&
            yourIDX !== gameState?.game?.current_player
        ) {
            return;
        }

        let game = gameState?.game;

        if (isBackendLoaded && game) {
            let new_game: Game = tilerBackend.step(
                {
                    field: game.field,
                    players: game.players,
                    current_player: game.current_player,
                    count_colors: game.count_colors,
                    seed: game.seed,
                    steps: game.steps,
                },
                color
            );
            if (gameState?.type !== 'online' && currentGame.gameType === 0) {
                new_game = tilerBackend.bot_step(
                    {
                        field: new_game.field,
                        players: new_game.players,
                        current_player: new_game.current_player,
                        count_colors: new_game.count_colors,
                        seed: new_game.seed,
                        steps: new_game.steps,
                    },
                    store.bot_level
                );
            }
            if (
                gameState?.type === 'online' &&
                game.current_player === yourIDX
            ) {
                move(color);
            }
            if (gameState) setGameState({ ...gameState, game: new_game });
        }
    };

    if (
        !isBackendLoaded ||
        !gameState?.game ||
        (gameState.type === 'online' && !isLobbyReady) ||
        loadingDescription
    ) {
        // let link = lobby && store.multiplayerType === 1 ? `${HOST}/game?lobby=${lobby}` : undefined
        if (lobbyLink && !!!loadingDescription) {
            return <Loading title={<LinkCopy link={lobbyLink} />} />;
        }
        return (
            <Loading
                title={
                    loadingDescription ? loadingDescription[lang] : undefined
                }
            />
        );
    }

    return (
        <>
            <ModalWin
                isOpen={
                    gameState.game?.is_game_over &&
                    gameState.game?.winner === yourIDX
                }
                onClose={() => {
                    navigate('/');
                    if (gameState.type === 'offline') currentGame.reset();
                }}
            />
            <ModalLost
                isOpen={
                    gameState.game?.is_game_over &&
                    gameState.game?.winner !== yourIDX
                }
                onClose={() => {
                    navigate('/');
                    if (gameState.type === 'offline') currentGame.reset();
                }}
            />
            <div className="game-page-main">
                <div className="game-header">
                    <div
                        className="game-field-heder-color"
                        style={{
                            backgroundColor:
                                colors[gameState.game.players[yourIDX].color],
                            opacity:
                                gameState.game.current_player === yourIDX
                                    ? 1
                                    : 0.3,
                        }}
                    />
                    <span>
                        {gameState.game.players[yourIDX].points} :{' '}
                        {gameState.game.players[(yourIDX + 1) % 2].points}
                    </span>
                    <div
                        className="game-field-heder-color"
                        style={{
                            backgroundColor:
                                colors[
                                    gameState.game.players[(yourIDX + 1) % 2]
                                        .color
                                ],
                            opacity:
                                gameState.game.current_player ===
                                (yourIDX + 1) % 2
                                    ? 1
                                    : 0.3,
                        }}
                    />
                </div>
                <div className="game-field-main">
                    <div className="game-field">
                        {/* <TransformWrapper options={{maxScale: 10}}>
							<TransformComponent> */}
                        <svg
                            ref={svgRef}
                            viewBox={`0 0 ${fieldWidth ?? 0} ${
                                fieldHeight ?? 0
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                transform: yourIDX === 0 ? '' : 'scale(-1, -1)',
                            }}
                        >
                            {svgList}
                        </svg>
                        {/* </TransformComponent>
						</TransformWrapper> */}
                    </div>
                </div>
                {!(store.game_type === 2 && !isPlayer) &&  
                    <div
                        className={
                            gameState.game.current_player === yourIDX ||
                            currentGame.gameType !== 1
                                ? 'color-picker-main-0'
                                : 'color-picker-main-1'
                        }
                    >
                        <div
                            className="color-picker"
                            style={{
                                opacity:
                                    currentGame.gameType === 1 ||
                                    yourIDX === gameState.game?.current_player
                                        ? 1
                                        : 0.3,
                            }}
                        >
                            {colors
                                .slice(0, gameState.game.count_colors)
                                .map((color, idx) => {
                                    if (
                                        idx === gameState.game?.players[0].color ||
                                        idx === gameState.game?.players[1].color
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            key={idx}
                                            onClick={() => handleMove(idx)}
                                            className="color-button"
                                            style={{ backgroundColor: color }}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default React.memo(GamePage);
