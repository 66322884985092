import Field from 'types/field';
import PlayerState from 'types/player';

type Game = {
    field: Field;
    players: PlayerState[];
    current_player: number;
    count_colors: number;
    seed: Int8Array;
    steps: Array<[number, number]>;
    is_game_over: boolean;
    winner?: number;
};

export const roles = ['PLAYER', 'SPECTATOR', 'CREATOR'] as const

export type PlayerRole = typeof roles[number];

export type GameProps = {
    game_type: number;
    bot_level: number;
    field_type: number;
    count_colors: number;
    field_size: number;
    start_cells_type: number;
    seed?: Int8Array;
    current_player?: number;
    steps?: Array<[number, number]>;
    multiplayerType: number;
    multiplayerGameType: number;
    playerRole: PlayerRole
};

export type GameParams = {
    field_type: number;
    field_size: number;
    count_colors: number;
    start_cells_type: number;
};

export type CurrentOfflineGame = {
    gameType?: number;
    botLevel?: number;
    game?: Game;
};

export type GameState = {
    type: 'offline' | 'online';
    game?: Game;
    isReady: boolean;
};

export default Game;
